import React from "react"
import PageContainer from "../containers/PageContainer"
import HeadData from "../data/HeadData"
import PageHeaderImageBg from '../headers/PageHeaderImageBg'
import PageHeaderText from "../headers/PageHeaderText"
import HeaderNav from "../navigation/HeaderNav"
import HomeAffiliationSection from "../sections/HomeAffiliationSection"
import CTASection from "../sections/CTASection"
import PageTermsSection from "../sections/PageTermsSection"

export default () => (
  <PageContainer>
    <HeadData 
      title='Terms of Service' 
      description='By accessing our website, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws.'
    />

    <PageHeaderImageBg>
      <HeaderNav />
      <PageHeaderText
        title='Terms of Service'
      />
    </PageHeaderImageBg>

    <PageTermsSection />

    <HomeAffiliationSection />

    <CTASection />
  </PageContainer>
)
