import React from 'react'
import styled from 'styled-components'

const StyledUnderline = styled.div`
  width: 50px;
  height: 4px;
  background: #2c5282;
  margin-top: 50px;
  margin-bottom: 22px;
`

const PageTermsSection = () => (  
  <div className='bg-gray-200'>
    <div className='lg:w-10/12 mx-auto py-20 sm:py-6 sm:px-4 md:py-12 md:px-8'>
      <h2 className='font-display text-gray-900 text-3xl sm:text-2xl leading-snug mb-6'>Interpacific Asset Management Terms of Service</h2>

      <StyledUnderline></StyledUnderline>

      <div className='w-9/12 sm:w-full'>

        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>1. Terms</h3>
        <p className='text-xl text-gray-900 mb-6'>By accessing the website at www.interpacificmgmt.com, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>2. Use License</h3>
        <ol className='list-disc text-xl text-gray-900 mb-6 ml-8'>
          <li>Permission is granted to temporarily download one copy of the materials (information or software) on Interpacific Asset Management's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
            <ol className='list-decimal text-xl text-gray-900 mb-6 mt-6 ml-12'>
                <li className='mb-4'>modify or copy the materials;</li>
                <li className='mb-4'>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                <li className='mb-4'>attempt to decompile or reverse engineer any software contained on Interpacific Asset Management's website;</li>
                <li className='mb-4'>remove any copyright or other proprietary notations from the materials; or</li>
                <li className='mb-4'>transfer the materials to another person or "mirror" the materials on any other server.</li>
            </ol>
          </li>
          <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Interpacific Asset Management at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
        </ol>
        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>3. Disclaimer</h3>
        <ol className='list-disc text-xl text-gray-900 mb-6 ml-8'>
          <li className='mb-4'>The materials on Interpacific Asset Management's website are provided on an 'as is' basis. Interpacific Asset Management makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
          <li>Further, Interpacific Asset Management does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
        </ol>
        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>4. Limitations</h3>
        <p className='text-xl text-gray-900 mb-6'>In no event shall Interpacific Asset Management or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Interpacific Asset Management's website, even if Interpacific Asset Management or a Interpacific Asset Management authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>5. Accuracy of materials</h3>
        <p className='text-xl text-gray-900 mb-6'>The materials appearing on Interpacific Asset Management's website could include technical, typographical, or photographic errors. Interpacific Asset Management does not warrant that any of the materials on its website are accurate, complete or current. Interpacific Asset Management may make changes to the materials contained on its website at any time without notice. However Interpacific Asset Management does not make any commitment to update the materials.</p>
        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>6. Links</h3>
        <p className='text-xl text-gray-900 mb-6'>Interpacific Asset Management has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Interpacific Asset Management of the site. Use of any such linked website is at the user's own risk.</p>
        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>7. Modifications</h3>
        <p className='text-xl text-gray-900 mb-6'>Interpacific Asset Management may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
        <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>8. Governing Law</h3>
        <p className='text-xl text-gray-900 mb-6'>These terms and conditions are governed by and construed in accordance with the laws of California and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
      </div>
    </div>
  </div>      
)

export default PageTermsSection